import React from "react"
import { Link } from "gatsby"

import { PageSectionWrap, PageTitle, Story, PageForm } from "../objects"
import FadeUp from "../fade-in-up"
import Form from "../form"

export default ({ title, summary }) => {
  const formKeys = {
    clientName: "",
    clientEmail: "",
    clientNumber: "",
    clientNeed: "",
    orgName: "",
  }

  return (
    <PageSectionWrap bkg="gold">
      <PageTitle dark>
        <FadeUp>{title}</FadeUp>
      </PageTitle>

      <Story>
        <FadeUp>
          <p>{summary}</p>
        </FadeUp>
      </Story>

      <PageForm
        name="inquire"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input name="bot-field" type="hidden" />
        <FadeUp>
          <Form mode="light" formName="inquire" formKeys={formKeys}>
            <section className="form-header">
              <h3>How can we help?</h3>
              <p>
                A Switch40 representative will contact you after submitting the
                form below.
              </p>
            </section>

            <div className="form-row">
              <label>
                <span>What's the name of your organization/firm?</span>
                <input
                  className="form-input"
                  type="text"
                  required
                  name="orgName"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>Your Name</span>
                <input
                  className="form-input"
                  type="text"
                  required
                  name="clientName"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>Email</span>
                <input
                  className="form-input"
                  type="email"
                  required
                  name="clientEmail"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>Direct Line</span>
                <input
                  className="form-input"
                  type="text"
                  required
                  name="clientNumber"
                />
              </label>
            </div>

            <div className="form-row">
              <label>
                <span>What staffing solutions do you need?</span>
                <select name="clientNeed" required className="form-input">
                  <option value=""></option>
                  <option value="Temporary Staff">Temporary Staff</option>
                  <option value="Permanent Staff">Permanent Staff</option>
                  <option value="Both">Both</option>
                </select>
              </label>
            </div>

            <div className="form-row">
              <button>
                <span>Submit</span>
                <figure></figure>
              </button>
            </div>

            <p className="legal">
              *By clicking 'Submit', you agree with the handling of your data in
              accordance with the Switch40{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>
          </Form>
        </FadeUp>
      </PageForm>
    </PageSectionWrap>
  )
}
