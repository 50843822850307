import React from "react"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"

import Page from "../components/page"
import { PageBkg } from "../components/objects"
import Inquire from "../components/find-staff/inquire"

export default ({ data }) => {
  let node = data.prismic.allFind_staffs.edges[0].node

  return (
    <>
      <Meta title="Find Work - Switch40" />
      <Page photo={node.page_image.url}>
        <PageBkg>
          <Inquire title={node.title[0].text} summary={node.summary[0].text} />
        </PageBkg>
      </Page>
    </>
  )
}

export const query = graphql`
  {
    prismic {
      allFind_staffs {
        edges {
          node {
            page_image
            title
            summary
          }
        }
      }
    }
  }
`
